body,
html {
  font-family: Hind, Helvetica, sans-serif !important;
}

.app-header {
  height: "65px" !important;
}

.footer-banner {
  padding-inline: 250px;
}

@media (max-width: 768px) {
  .footer-banner {
    padding-inline: 45px;
  }
}

.required-astrix {
  color: red;
  font-size: 15px;
  z-index: 1;
  padding-left: 5px;
}
